import React from "react";
import "./Sections.css";

const section = props => (
  <div className="sections">
    <div className="section section--slanted section--what-we-do">
      <div className="section__container">
        <h2>What is a Cully Code?!</h2>
        <p>
          Cully Code LIMITED specialises in app and web design and engineering.
          We make our own mobile apps, progressive web apps and websites. We
          also take on contract work helping our clients build and ship quality,
          reliable code.
        </p>
      </div>
    </div>
    <div className="section section--slanted section--who-we-are">
      <div className="section__container">
        <h2>Who are Cully Code?!</h2>
        <p>
          Thomas is an experienced software engineer with a history of success
          as both an app and web developer. Tom is able to adapt into different
          environments without sacrificing code standards and quality. This has
          led to being a part of the success of several award winning apps and
          games. Have a look at his{" "}
          <a href="https://www.linkedin.com/in/thomas-cullinane-01531831/">
            LinkedIn
          </a>{" "}
          for a more detailed history.
        </p>
        <p>
          Charlotte is a lawyer turned app designer. After being called to the
          bar in 2015 and working in the law for a few years, Charlotte decided
          that working life could be more fun and hung up the wig to move into
          tech. Charlotte is researching and designing apps that Cully Code hope
          to bring to the app stores in the very near future!
        </p>
      </div>
    </div>
    <div className="section section--contact">
      <div className="section__container">
        <h2>How to Cully Code?!</h2>
        <p>
          Questions about one of our apps? Need an app? Need a website? Do you
          need an experienced developer on your team? Do you fancy a chat?
        </p>
        <p>
          Email us at <a href="mailto:hello@cullycode.co.uk">hello@cullycode.co.uk</a> to
          see how we can help you
        </p>
      </div>
    </div>
    <div className="section section--company-info">
      <p>Cully Code LIMITED</p>
      <p>VAT Number: 281706791</p>
      <p>Companies House Number: 11054042</p>
      <p>Copyright © {new Date().getFullYear()}</p>
    </div>
  </div>
);

export default section;
